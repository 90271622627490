.feed-item {
  @apply text-base text-gray-600 ml-3 pl-4
}

.feed-item ul {
  @apply list-disc list-inside
}

.feed-item ol {
  @apply list-decimal list-inside
}
