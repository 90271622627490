@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    @apply bg-white overflow-y-scroll
}

.bg-primary{
    background-color: #8b60ff;
}

.text-primary {
    color: #8b60ff;
}

.bg-secondary {
    background-color: #581aff;
}

.text-primary {
    color: #581aff;
}

.btn{
    @apply text-sm uppercase font-semibold rounded-3xl text-white;
    min-width: 80px;
    height: 2.5rem;
    min-height: 2.5rem;
    line-height: 2.5rem;
    background-color: #8b60ff;
}

.btn-xs {
    font-size: .75rem;
    height: 1.5rem;
    min-height: 1.5rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.btn[disabled]{
    background-color: #8b60ff !important;
    opacity: 0.2;
    color: #000;
}

.btn:hover{
    background-color: #581aff;
}

.btn-ghost{
    @apply bg-transparent text-black;
}

.btn-ghost:hover{
    background: rgba(0, 0, 0, .15);
}

.btn-circle{
    @apply min-w-0 rounded-full;
    height: 1.5rem;
    padding: 0;
    width: 1.5rem;
}

.btn-circle svg{
    @apply mx-auto;
}

.btn-sm{

}

.quill > .ql-container > .ql-editor{
    @apply text-lg;
    /* min-h-16 */
    min-height: 64px;

}


.ql-editor.ql-blank::before{
    font-style: normal;
}

mgt-people-picker {
    --input-border: 2px rgba(255, 255, 255, 0) solid;
    /* sets all input area border */

    --input-background-color: transparent;
    /* input area background color */
    --input-border-color--hover: transparent;
    /* input area border hover color */
    --input-border-color--focus: transparent;
    /* input area border focus color */

    --color: #000;
    /* input area border focus color */
    --placeholder-color: rgba(0, 0, 0, 0.5);
    /* placeholder text color */
    --placeholder-color--focus: rgba(255, 255, 255, 0.8);
    /* placeholder text focus color */
}
